import { useEffect, type ComponentProps } from 'react';
import { ButtonSignupLogin } from '@hungryroot/components-customer';
import { useExperiment } from '@hungryroot/experiments';
import { log } from '@hungryroot/o11y';
import { islandRoot } from '../IslandRoot';
import styles from './ButtonSignupLoginIsland.module.css';

export const ButtonSignupLoginIsland = islandRoot(function (
  props: ComponentProps<typeof ButtonSignupLogin>
) {
  const variant = useExperiment(153);

  useEffect(() => {
    if (variant === undefined) {
      return;
    }

    log('exp 153', {
      variant,
    });
  }, [variant]);

  return <ButtonSignupLogin {...props} className={styles.login} />;
});
